<template>
  <div
    class="auth-v2 auth-wrapper auth-bg d-flex justify-content-center align-items-center"
  >
    <div class="card-container">
      <!-- Login -->
      <b-card lg="4" class="d-flex px-2 pb-5 py-2">
        <div sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- Brand logo-->
          <div class="logo-container mb-1">
            <b-img fluid :src="imgUrl" alt="Login V2" class="logo" />
          </div>
          <!-- /Brand logo-->

          <b-card-text class="h3 mb-2 font-medium-3 text-center">
            MINIDC ADMIN
          </b-card-text>
          <b-card-text class="h2 my-2 text-black fw-bold text-center fw-bold">
            Log In
          </b-card-text>
          <b-card-text class="mb-5 px-2 font-small-3 text-center">
            Enter your email and password below
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link to="/auth-forgot-password">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <b-container class="d-flex justify-content-center my-1">
                <div
                  v-if="loading"
                  class="spinner-border text-primary"
                  role="status"
                />
              </b-container>
              <!-- submit buttons -->
              <b-button class="py-1" type="submit" variant="primary" block>
                Log In
              </b-button>
            </b-form>
          </validation-observer>

          <!-- REGISTRATION - not needed -->
          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->
        </div>
      </b-card>
      <!-- Login -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import CompanyLogo from "../../components/CompanyLogo.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CompanyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: "",
      userEmail: "",
      password: "",
      admin: 1,
      sideImg: require("@/assets/images/logo/logo-red.png"),
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/logo/logo-white.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      const self = this;
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          self.loading = true;
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              admin: this.admin,
            })
            .then((response) => {
              self.loading = false;
              if (response.data.status === true) {
                useJwt.setToken(response.data.access_token);
                localStorage.setItem(
                  "userData",
                  JSON.stringify(response.data.userdata)
                );
                // this.$ability.update(userData.ability)
                this.$router.go(0);
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Uh oh! 😲😮",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: "That Username or Password seems to be invalid.",
                  },
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.auth-bg {
  background-color: #363740;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 60px;
  width: auto;
}
</style>
